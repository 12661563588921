import {
  DISTRIBUTOR_STAFF,
  MASTER_DISTRIBUTOR_HUB,
  PO_MANAGER,
  SOURCING_MANAGER,
} from 'common/config/acl'
import { useAppContext } from 'contexts/AppContext'
import { Warehouse } from 'interfaces/warehouse'
import { get } from 'lodash'
import { useSession } from 'next-auth/react'
import { stringify } from 'qs'
import { useEffect, useMemo, useState } from 'react'
import axiosInstance from 'utils/axiosInstance'
import {
  defaultStringifyOption,
  getResponseData,
  isIndia,
} from 'utils/commonUtils'

import useACL from './useACL'

//Use only for india
const useGetDataOwnerShip = (isGetWarehouseIDs?: boolean) => {
  const { data: session } = useSession()
  const [warehouseOwnerShip, setWarehouseOwnerShip] = useState<Warehouse[]>([])
  const {
    state: { warehouses, isRoleWarehouseOwner },
  } = useAppContext()
  const { userRoles } = useACL()

  const isPoManagerOrSourcing = useMemo(() => {
    return [
      PO_MANAGER,
      SOURCING_MANAGER,
      MASTER_DISTRIBUTOR_HUB,
      DISTRIBUTOR_STAFF,
    ].some((role) => userRoles.includes(role))
  }, [userRoles])
  const isMasterDistributorHub = useMemo(() => {
    return [MASTER_DISTRIBUTOR_HUB].some((role) => userRoles.includes(role))
  }, [userRoles])
  const provinceIDs = useMemo(
    () => get(session?.user, 'userProvinces')?.map((item) => item.provinceID),
    [session]
  )
  const getWarehouseByProvince = async () => {
    if (isRoleWarehouseOwner) {
      setWarehouseOwnerShip(warehouses)
      return
    }
    try {
      let data: Warehouse[] = []
      const existWarehouseIDs = {}
      const params = stringify(
        {
          provinceIDs,
          includeDistributor: true,
          ignorePerPage: true,
          perPage: 1000,
        },
        defaultStringifyOption
      )
      if (provinceIDs.length > 0 && isIndia) {
        const response = await axiosInstance.get(`v1/warehouses?${params}`)
        data = getResponseData<Warehouse[]>(response)
      }
      if (isMasterDistributorHub) {
        data = [...data, ...warehouses]?.reduce((prev, curr) => {
          if (existWarehouseIDs[curr.id]) {
            return prev
          }
          existWarehouseIDs[curr.id] = true
          return [...prev, curr]
        }, [])
      }
      setWarehouseOwnerShip(data)
    } catch (error) {
      console.log('🚀 ~ getWarehouseByProvince ~ error:', error)
    }
  }

  useEffect(() => {
    let isMounted = true

    if (
      isMounted &&
      isGetWarehouseIDs &&
      (isPoManagerOrSourcing || isMasterDistributorHub)
    ) {
      getWarehouseByProvince()
      return
    }

    return () => {
      isMounted = false
    }
  }, [
    isGetWarehouseIDs,
    warehouses,
    isPoManagerOrSourcing,
    isMasterDistributorHub,
    isRoleWarehouseOwner,
  ])

  return { warehouseOwnerShip, provinceIDs, isPoManagerOrSourcing }
}

export default useGetDataOwnerShip
