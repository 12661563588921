import {
  isAdminMarketplace,
  isDistributorStaff,
  isSaleSupervisor,
  MASTER_DISTRIBUTOR_HUB,
} from 'common/config/acl'
import useACL from 'hooks/useACL'
import useGetDistributorID from 'hooks/useGetDistributorID'
import { Distributor } from 'interfaces/distributor'
import { IUser, UserProvince } from 'interfaces/user'
import { Warehouse } from 'interfaces/warehouse'
import { get, isArray } from 'lodash'
import { useSession } from 'next-auth/react'
import { stringify } from 'qs'
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import axiosInstance from 'utils/axiosInstance'
import {
  defaultStringifyOption,
  getResponseData,
  isIndia,
} from 'utils/commonUtils'

type ContextProviderStoreType = {
  warehouses?: Warehouse[]
  distributors?: Distributor[]
  userHighLevelProvince?: UserProvince[]
  isRoleWarehouseOwner?: boolean
}
const defaultFilters = {
  perPage: 1000,
  ignorePerPage: true,
  includeDistributor: true,
}
const initialState: ContextProviderStoreType = {
  warehouses: [],
  distributors: [],
  userHighLevelProvince: [],
  isRoleWarehouseOwner: false,
}

const store = createContext<{
  state: ContextProviderStoreType
  dispatch: Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})
const { Provider } = store

export const GET_WAREHOUSE_DATA_ROLE_ADMIN_MARKETPLACE = 'getWarehouseData'

export const GET_DATA_WAREHOUSE_MASTER_DISTRIBUTOR_HUB = 'getDataWarehouseHub'
const getDataWarehouse = async (email: string) => {
  try {
    const params = stringify(
      { email, includeUserWarehouse: true },
      defaultStringifyOption
    )
    const responseUser = await axiosInstance.get(`/v1/users?${params}`)
    const user = getResponseData<IUser[]>(responseUser)
    const { userWarehouse } = get(user, '[0]')
    if (isArray(userWarehouse) && userWarehouse.length > 0) {
      const ids = userWarehouse.map((item) => item?.warehouseID)
      const params = stringify(
        { ids, ...defaultFilters },
        defaultStringifyOption
      )
      const res = await axiosInstance.get(`/v1/warehouses?${params}`)
      const warehouses = getResponseData<Warehouse[]>(res)
      let existDistributor = {}
      const distributors = warehouses?.reduce((prev, curr) => {
        const { distributor } = curr
        if (existDistributor[distributor?.id]) {
          return prev
        }
        existDistributor = { ...existDistributor, [distributor?.id]: true }
        return [...prev, distributor]
      }, [])
      return { warehouses, distributors }
    }
    return null
  } catch (error) {
    console.log('🚀 ~ getWarehouse ~ error:', error)
  }
}

const actionReducer = (state: ContextProviderStoreType, action: any) => {
  switch (action.type) {
    case GET_WAREHOUSE_DATA_ROLE_ADMIN_MARKETPLACE: {
      return { ...state, ...action.payload }
    }
    case GET_DATA_WAREHOUSE_MASTER_DISTRIBUTOR_HUB: {
      return { ...state, ...action.payload }
    }
  }
}

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(actionReducer, initialState)
  const { data: session } = useSession()
  const { userRoles } = useACL()
  const isRoleAdminMarketplace = useMemo(
    () => isAdminMarketplace(userRoles),
    [userRoles]
  )
  const isRoleSS = useMemo(() => isSaleSupervisor(userRoles), [userRoles])
  const isRoleDistributorStaff = useMemo(
    () => isDistributorStaff(userRoles),
    [userRoles]
  )
  const isRoleWarehouseOwner = useMemo(
    () => isRoleAdminMarketplace || isRoleSS || isRoleDistributorStaff,
    [isRoleSS, isRoleDistributorStaff, isRoleAdminMarketplace]
  )

  const { distributorID, finish } = useGetDistributorID(true)
  const isRoleMasterDistributorHub = useMemo(
    () => isIndia && userRoles.includes(MASTER_DISTRIBUTOR_HUB),
    [userRoles]
  )

  const getInitialValue = async (isRoleWarehouseOwner: boolean) => {
    const email = get(session, 'user.email', []) as string
    const data = await getDataWarehouse(email)
    if (data) {
      dispatch({
        type: GET_WAREHOUSE_DATA_ROLE_ADMIN_MARKETPLACE,
        payload: { ...data, isRoleWarehouseOwner },
      })
    }
  }
  const getInitialWarehouseDistributorHub = async (distributorID: string) => {
    try {
      const params = stringify(
        { distributorID, ...defaultFilters },
        defaultStringifyOption
      )
      const res = await axiosInstance.get(`/v1/warehouses?${params}`)
      const data = getResponseData<Warehouse[]>(res)
      let existDistributor = {}
      const distributors = data?.reduce((prev, curr) => {
        const { distributor } = curr
        if (existDistributor[distributor?.id]) {
          return prev
        }
        existDistributor = { ...existDistributor, [distributor?.id]: true }
        return [...prev, distributor]
      }, [])
      if (isArray(data) && data.length > 0) {
        dispatch({
          type: GET_DATA_WAREHOUSE_MASTER_DISTRIBUTOR_HUB,
          payload: { warehouses: data, distributors },
        })
      }
    } catch (error) {
      console.log('🚀 ~ getWarehouse ~ error:', error)
    }
  }
  useEffect(() => {
    if (session?.user && isRoleWarehouseOwner && finish) {
      getInitialValue(isRoleWarehouseOwner)
      return
    }
    if (
      session?.user &&
      isRoleMasterDistributorHub &&
      finish &&
      distributorID
    ) {
      getInitialWarehouseDistributorHub(distributorID)
      return
    }
  }, [
    session,
    isRoleMasterDistributorHub,
    finish,
    distributorID,
    isRoleWarehouseOwner,
  ])

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export const useAppContext = () => useContext(store)
export default store
